/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./system-home.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./system-home.component";
var styles_SystemHomeComponent = [i0.styles];
var RenderType_SystemHomeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SystemHomeComponent, data: {} });
export { RenderType_SystemHomeComponent as RenderType_SystemHomeComponent };
export function View_SystemHomeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "section", [["class", "system"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [["class", "system-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["O QUE \u00C9 A ARENA PLANET HEALTH?"])), (_l()(), i1.ɵeld(3, 0, null, null, 4, "p", [["class", "system-subtitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["A ARENA PLANET HEALTH \u00E9 um centro de treinamento integrado de sa\u00FAde e performance. "])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Oferecemos o que h\u00E1 de mais moderno e eficiente para voc\u00EA conquistar resultados est\u00E9ticos incr\u00EDveis atrav\u00E9s de exerc\u00EDcios funcionais que tenham transfer\u00EAncia para o seu dia-dia e esportes. Com uma equipe de educadores f\u00EDsicos, nutricionista e fisioterapeuta altamente qualificados, voc\u00EA vai participar do SISTEMA DE RESULTADOS ARENA PERFORMANCE!"])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "system-buttom"], ["routerLink", "/sistema-de-resultados"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, [" CONHE\u00C7A O NOSSO SISTEMA DE RESULTADOS "]))], function (_ck, _v) { var currVal_0 = "/sistema-de-resultados"; _ck(_v, 9, 0, currVal_0); }, null); }
export function View_SystemHomeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-system-home", [], null, null, null, View_SystemHomeComponent_0, RenderType_SystemHomeComponent)), i1.ɵdid(1, 114688, null, 0, i3.SystemHomeComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SystemHomeComponentNgFactory = i1.ɵccf("app-system-home", i3.SystemHomeComponent, View_SystemHomeComponent_Host_0, {}, {}, []);
export { SystemHomeComponentNgFactory as SystemHomeComponentNgFactory };
