<ae2-header-widget [config]="headerConfig" [signupConfig]="signupConfig">
  <ng-template #headerSidenavTemplate let-sidenav>
    <div class="ae2-header-sidenav-closer" [class.active]="sidenav.isOpened" (click)="sidenav.closeSidenav()">&nbsp;</div>
	<div class="ae2-header-sidenav" [class.active]="sidenav.isOpened">

		<ng-container *ngIf="sidenav.isLoggedIn; else notLoggedIn">
			<div fxLayout="column" fxLayoutAlign="start stretch">

				<ng-template [ngTemplateOutlet]="menuItems"></ng-template>

				<mat-divider></mat-divider>

				<ng-container *ngFor="let item of sidenav.myAccountMenu">
					<div (click)="sidenav.closeSidenav()" [routerLink]="['/', 'minha-conta', item.link]" class="ae2-header-sidenav-item-myaccount"
					 routerLinkActive="active-link" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
						{{item.name}}
					</div>
				</ng-container>

			</div>
		</ng-container>

		<ng-template #notLoggedIn>
			<div fxLayout="column" fxLayoutAlign="start stretch">

				<ng-template [ngTemplateOutlet]="menuItems"></ng-template>
			</div>
		</ng-template>

		<ng-template #menuItems>

			<mat-divider></mat-divider>

			<ng-container *ngFor="let item of sidenav.items.items">
				<div *ngIf="['PAGE','SESSION'].indexOf(item.type) >= 0" class="ae2-header-sidenav-item" fxLayout="row" fxLayoutAlign="start center">
					<div class="ae2-header-sidenav-item-page" *ngIf="item.type === 'PAGE'">
						<a [routerLink]="item.url" (click)="sidenav.closeSidenav()" class="sidenav-link">
							<span>{{ item.label }}</span>
						</a>
					</div>
					<div class="ae2-header-sidenav-item-session" *ngIf="item.type === 'SESSION'">
						<a (click)="sidenav.navigator(item.url); sidenav.closeSidenav()" class="sidenav-link">
							<span>{{ item.label }}</span>
						</a>
					</div>
				</div>
			</ng-container>

			<mat-divider></mat-divider>

			<ng-container *ngIf="sidenav.subitems && sidenav.subitems.length > 0">
				<div *ngFor="let subitem of sidenav.subitems" class="ae2-header-sidenav-item" fxLayout="row" fxLayoutAlign="start center">
					<div class="ae2-header-sidenav-item-page" *ngIf="subitem.type === 'PAGE'">
						<a [routerLink]="subitem.url" (click)="sidenav.closeSidenav()" class="sidenav-link">
							<span>{{ subitem.label }}</span>
						</a>
					</div>
					<div class="ae2-header-sidenav-item-session" *ngIf="subitem.type === 'SESSION'">
						<a (click)="sidenav.navigator(subitem.url); sidenav.closeSidenav()" class="sidenav-link">
							<span>{{ subitem.label }}</span>
						</a>
					</div>
				</div>
			</ng-container>
		</ng-template>


	</div>
  </ng-template>
</ae2-header-widget>
<ae2-header-layout-placement-widget></ae2-header-layout-placement-widget>
