<section class="system">
    <h2 class="system-title">O QUE É A ARENA PLANET HEALTH?</h2>
    <p class="system-subtitle">A ARENA PLANET HEALTH é um centro de treinamento integrado de saúde e performance.
        <br><br>Oferecemos o que há de mais moderno e eficiente para você conquistar resultados estéticos incríveis
        através de exercícios funcionais que tenham transferência para o seu dia-dia e esportes. Com uma equipe de
        educadores físicos, nutricionista e fisioterapeuta altamente qualificados, você vai participar do SISTEMA DE
        RESULTADOS ARENA PERFORMANCE!</p>
    <div class="system-buttom" routerLink="/sistema-de-resultados">
        CONHEÇA O NOSSO SISTEMA DE RESULTADOS
    </div>
</section>