import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { Ae2CarouselContainerModule } from '@angularecommerce/core/components/carousel-container';
import { MatButtonModule } from '@angular/material';
import { TrainersModule } from '../../shared/components/trainers/trainers.module';
import { PackageModule } from '../../shared/components/package/package.module';
import { ContactModule } from '../../shared/components/contact/contact.module';
import { SystemResultModule } from '../../shared/components/system-result/system-result.module';
import { SystemHomeModule } from '../../shared/components/system-home/system-home.module';
import { CheckTrainingsModule } from '../../shared/components/check-trainings/check-trainings.module';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    Ae2CarouselContainerModule,
    TrainersModule,
    PackageModule,
    ContactModule,
    RouterModule,
    SystemResultModule,
    CheckTrainingsModule,
    SystemHomeModule
  ],
  declarations: [HomeComponent]
})
export class HomeModule { }
