<ae2-carousel-container carouselCode="home" [config]="carouselConfig">
</ae2-carousel-container>

<app-system-home></app-system-home>
<app-check-trainings></app-check-trainings>

<!-- <app-list-units id="estudio"></app-list-units>

<app-activities id="atividades"></app-activities>

<app-trainers id="instrutores"></app-trainers>

<app-package id="pacote"></app-package>

<app-calendar-home id="agenda"></app-calendar-home>

<app-contact id="contato"></app-contact> -->