import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'addressCreate'
})
export class AddressPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const address = [
      value.address1,
      value.address2,
      value.address3,
      value.address4
    ].filter(item => Boolean(item)).join(', ');

    return `${address} <br> ${value.city}/${value.state}`;
  }

}
