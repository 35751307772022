import { NgModule } from '@angular/core';
import { SystemHomeComponent } from './system-home.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    RouterModule
  ],
  declarations: [SystemHomeComponent],
  exports: [SystemHomeComponent]
})
export class SystemHomeModule { }
