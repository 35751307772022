import { NgModule, Injectable } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules, Resolve } from '@angular/router';
import { Ae2AuthRouteGuardService, Ae2AuthRouteGuardServiceModule } from '@angularecommerce/core/services/auth-route-guard';
import { AE2_ROUTER_CLASSES_DATA } from '@angularecommerce/core/directives/router-classes';
import { HomeComponent } from './features/home/home.component';
import { HomeModule } from './features/home/home.module';

import { map, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Ae2ActivityModel } from '@angularecommerce/core/services/activities/activities.model';
import { Ae2ActivitiesService, Ae2ActivitiesServiceGetAllQuery } from '@angularecommerce/core/services/activities';
export const CALENDAR_RESOLVER_CACHE_KEY = 'calendar-filter-default-activity';
export const CALENDAR_RESOLVER_EVENT_CACHE_KEY = 'calendar-filter-default-activity-event';

@Injectable()
export class CalendarResolver implements Resolve<any> {

  constructor(
    private ae2ActivitiesService: Ae2ActivitiesService
  ) { }

  resolve(): Observable<Ae2ActivityModel> {
    if (localStorage.getItem(CALENDAR_RESOLVER_CACHE_KEY)) {
      return of(JSON.parse(localStorage.getItem(CALENDAR_RESOLVER_CACHE_KEY)));
    }

    const query = new Ae2ActivitiesServiceGetAllQuery();
    query.active = true;
    query.use_as_filter = true;

    return this.ae2ActivitiesService.getAll(query)
      .getData$()
      .pipe(
        map(activities => activities.results[0]),
        tap(activity => localStorage.setItem(CALENDAR_RESOLVER_CACHE_KEY, JSON.stringify(activity)))
      );
  }
}

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'minha-conta',
    canActivate: [Ae2AuthRouteGuardService],
    loadChildren: 'app/features/profile/profile.module#ProfileModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'checkout',
    loadChildren: 'app/features/checkout/checkout.module#CheckoutModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'agenda',
    loadChildren: 'app/features/calendar/calendar.module#CalendarModule',
    resolve: {
      defaultActivity: CalendarResolver
    }
  },
  {
    path: 'instrutores',
    loadChildren: 'app/features/instructors/instructors.module#InstructorsModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'instrutor/:slug/:id',
    loadChildren: 'app/features/instructor/instructor.module#InstructorModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'clientes/resetar-senha/:uidb/:token',
    loadChildren: 'app/features/forgot/forgot.module#ForgotModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'clientes/resetar-senha',
    loadChildren: 'app/features/forgot/forgot.module#ForgotModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'faq',
    loadChildren: 'app/features/faq/faq.module#FaqModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'quem-somos',
    loadChildren: 'app/features/about/about.module#AboutModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'nossos-treinos',
    loadChildren: 'app/features/trainings/trainings.module#TrainingsModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'servicos-especiais',
    loadChildren: 'app/features/special-services/special-services.module#SpecialServicesModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'politica-de-privacidade',
    loadChildren: 'app/features/policy/policy.module#PolicyModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'pacotes',
    loadChildren: 'app/features/packages/packages.module#PackagesModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'sistema-de-resultados',
    loadChildren: 'app/features/results/results.module#ResultsModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  { path: '**', pathMatch: 'full', redirectTo: '' }
];

@NgModule({
  imports: [
    Ae2AuthRouteGuardServiceModule,
    HomeModule,
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule],
  providers: [
    CalendarResolver,
    Ae2ActivitiesService
  ]
})
export class AppRoutingModule { }
