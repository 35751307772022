import { NgModule } from '@angular/core';
import { CheckTrainingsComponent } from './check-trainings.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material';
import { Ae2ActivityListContainerModule } from '@angularecommerce/core/components/activity-list-container/activity-list-container.module';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatButtonModule,
    Ae2ActivityListContainerModule,
    RouterModule
  ],
  declarations: [CheckTrainingsComponent],
  exports: [CheckTrainingsComponent]
})
export class CheckTrainingsModule { }
