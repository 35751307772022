import { NgModule } from '@angular/core';
import { SystemResultComponent } from './system-result.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';


@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
  ],
  declarations: [SystemResultComponent],
  exports: [SystemResultComponent]
})
export class SystemResultModule { }
