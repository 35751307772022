/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "../../../../../node_modules/@angular/material/divider/typings/index.ngfactory";
import * as i5 from "@angular/material/divider";
import * as i6 from "../../../../../node_modules/@angularecommerce/core/components/header-widget/header-widget.component.ngfactory";
import * as i7 from "@angularecommerce/core/components/header-widget/header-widget.component";
import * as i8 from "@ngrx/store";
import * as i9 from "ng2-scroll-to-el/scrollTo.service";
import * as i10 from "@angularecommerce/core/services/settings/settings.service";
import * as i11 from "@angularecommerce/core/services/sticky-overlay-manager/sticky-overlay-manager.service";
import * as i12 from "@angular/flex-layout";
import * as i13 from "../../../../../node_modules/@angularecommerce/core/components/header-widget/header-layout-placement/header-layout-placement.component.ngfactory";
import * as i14 from "@angularecommerce/core/components/header-widget/header-layout-placement/header-layout-placement.component";
import * as i15 from "./header.component";
var styles_HeaderComponent = [i0.styles];
var RenderType_HeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderComponent, data: {} });
export { RenderType_HeaderComponent as RenderType_HeaderComponent };
function View_HeaderComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_HeaderComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "ae2-header-sidenav-item-myaccount"], ["fxLayout", "row"], ["fxLayoutAlign", "start center"], ["fxLayoutGap", "10px"], ["routerLinkActive", "active-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick() !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_v.parent.parent.context.$implicit.closeSidenav() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, [[11, 4]], 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(3, 3), i1.ɵdid(4, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 11, { links: 1 }), i1.ɵqud(603979776, 12, { linksWithHrefs: 1 }), (_l()(), i1.ɵted(7, null, [" ", " "]))], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, "/", "minha-conta", _v.context.$implicit.link); _ck(_v, 2, 0, currVal_0); var currVal_1 = "active-link"; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.name; _ck(_v, 7, 0, currVal_2); }); }
function View_HeaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["fxLayout", "column"], ["fxLayoutAlign", "start stretch"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_3)), i1.ɵdid(3, 540672, null, 0, i3.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "mat-divider", [["class", "mat-divider"], ["role", "separator"]], [[1, "aria-orientation", 0], [2, "mat-divider-vertical", null], [2, "mat-divider-horizontal", null], [2, "mat-divider-inset", null]], null, null, i4.View_MatDivider_0, i4.RenderType_MatDivider)), i1.ɵdid(5, 49152, null, 0, i5.MatDivider, [], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_4)), i1.ɵdid(7, 802816, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = i1.ɵnov(_v.parent, 6); _ck(_v, 3, 0, currVal_0); var currVal_5 = _v.parent.context.$implicit.myAccountMenu; _ck(_v, 7, 0, currVal_5); }, function (_ck, _v) { var currVal_1 = (i1.ɵnov(_v, 5).vertical ? "vertical" : "horizontal"); var currVal_2 = i1.ɵnov(_v, 5).vertical; var currVal_3 = !i1.ɵnov(_v, 5).vertical; var currVal_4 = i1.ɵnov(_v, 5).inset; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3, currVal_4); }); }
function View_HeaderComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_HeaderComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["fxLayout", "column"], ["fxLayoutAlign", "start stretch"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_6)), i1.ɵdid(2, 540672, null, 0, i3.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var currVal_0 = i1.ɵnov(_v.parent, 6); _ck(_v, 2, 0, currVal_0); }, null); }
function View_HeaderComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "ae2-header-sidenav-item-page"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "a", [["class", "sidenav-link"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_v.parent.parent.parent.parent.context.$implicit.closeSidenav() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], function (_ck, _v) { var currVal_2 = _v.parent.parent.context.$implicit.url; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).target; var currVal_1 = i1.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = _v.parent.parent.context.$implicit.label; _ck(_v, 4, 0, currVal_3); }); }
function View_HeaderComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "ae2-header-sidenav-item-session"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "a", [["class", "sidenav-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        _v.parent.parent.parent.parent.context.$implicit.navigator(_v.parent.parent.context.$implicit.url);
        var pd_0 = (_v.parent.parent.parent.parent.context.$implicit.closeSidenav() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.label; _ck(_v, 3, 0, currVal_0); }); }
function View_HeaderComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "ae2-header-sidenav-item"], ["fxLayout", "row"], ["fxLayoutAlign", "start center"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_10)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_11)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = (_v.parent.context.$implicit.type === "PAGE"); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.parent.context.$implicit.type === "SESSION"); _ck(_v, 4, 0, currVal_1); }, null); }
function View_HeaderComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_HeaderComponent_9)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpad(3, 2), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (_ck(_v, 3, 0, "PAGE", "SESSION").indexOf(_v.context.$implicit.type) >= 0); _ck(_v, 2, 0, currVal_0); }, null); }
function View_HeaderComponent_14(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "ae2-header-sidenav-item-page"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "a", [["class", "sidenav-link"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_v.parent.parent.parent.parent.context.$implicit.closeSidenav() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], function (_ck, _v) { var currVal_2 = _v.parent.context.$implicit.url; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).target; var currVal_1 = i1.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = _v.parent.context.$implicit.label; _ck(_v, 4, 0, currVal_3); }); }
function View_HeaderComponent_15(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "ae2-header-sidenav-item-session"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "a", [["class", "sidenav-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        _v.parent.parent.parent.parent.context.$implicit.navigator(_v.parent.context.$implicit.url);
        var pd_0 = (_v.parent.parent.parent.parent.context.$implicit.closeSidenav() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.label; _ck(_v, 3, 0, currVal_0); }); }
function View_HeaderComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "ae2-header-sidenav-item"], ["fxLayout", "row"], ["fxLayoutAlign", "start center"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_14)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_15)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = (_v.context.$implicit.type === "PAGE"); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.context.$implicit.type === "SESSION"); _ck(_v, 4, 0, currVal_1); }, null); }
function View_HeaderComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_13)), i1.ɵdid(2, 802816, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.subitems; _ck(_v, 2, 0, currVal_0); }, null); }
function View_HeaderComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-divider", [["class", "mat-divider"], ["role", "separator"]], [[1, "aria-orientation", 0], [2, "mat-divider-vertical", null], [2, "mat-divider-horizontal", null], [2, "mat-divider-inset", null]], null, null, i4.View_MatDivider_0, i4.RenderType_MatDivider)), i1.ɵdid(1, 49152, null, 0, i5.MatDivider, [], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_8)), i1.ɵdid(3, 802816, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "mat-divider", [["class", "mat-divider"], ["role", "separator"]], [[1, "aria-orientation", 0], [2, "mat-divider-vertical", null], [2, "mat-divider-horizontal", null], [2, "mat-divider-inset", null]], null, null, i4.View_MatDivider_0, i4.RenderType_MatDivider)), i1.ɵdid(5, 49152, null, 0, i5.MatDivider, [], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_12)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_4 = _v.parent.context.$implicit.items.items; _ck(_v, 3, 0, currVal_4); var currVal_9 = (_v.parent.context.$implicit.subitems && (_v.parent.context.$implicit.subitems.length > 0)); _ck(_v, 7, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).vertical ? "vertical" : "horizontal"); var currVal_1 = i1.ɵnov(_v, 1).vertical; var currVal_2 = !i1.ɵnov(_v, 1).vertical; var currVal_3 = i1.ɵnov(_v, 1).inset; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_5 = (i1.ɵnov(_v, 5).vertical ? "vertical" : "horizontal"); var currVal_6 = i1.ɵnov(_v, 5).vertical; var currVal_7 = !i1.ɵnov(_v, 5).vertical; var currVal_8 = i1.ɵnov(_v, 5).inset; _ck(_v, 4, 0, currVal_5, currVal_6, currVal_7, currVal_8); }); }
function View_HeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "ae2-header-sidenav-closer"]], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (_v.context.$implicit.closeSidenav() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A0"])), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "ae2-header-sidenav"]], [[2, "active", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_2)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["notLoggedIn", 2]], null, 0, null, View_HeaderComponent_5)), (_l()(), i1.ɵand(0, [["menuItems", 2]], null, 0, null, View_HeaderComponent_7))], function (_ck, _v) { var currVal_2 = _v.context.$implicit.isLoggedIn; var currVal_3 = i1.ɵnov(_v, 5); _ck(_v, 4, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.isOpened; _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit.isOpened; _ck(_v, 2, 0, currVal_1); }); }
export function View_HeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "ae2-header-widget", [], null, [["window", "scroll"], ["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onWindowScroll($event) !== false);
        ad = (pd_0 && ad);
    } if (("window:resize" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onResize() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i6.View_Ae2HeaderWidgetComponent_0, i6.RenderType_Ae2HeaderWidgetComponent)), i1.ɵdid(1, 4440064, null, 10, i7.Ae2HeaderWidgetComponent, [i2.Router, i8.Store, i9.ScrollToService, i1.ChangeDetectorRef, i10.Ae2SettingsService, i11.Ae2StickyOverlayManagerService, i1.Renderer2, i12.ObservableMedia], { config: [0, "config"], signupConfig: [1, "signupConfig"] }, null), i1.ɵqud(335544320, 1, { headerTemplate: 0 }), i1.ɵqud(335544320, 2, { headerLoginTemplate: 0 }), i1.ɵqud(335544320, 3, { headerLogoTemplate: 0 }), i1.ɵqud(335544320, 4, { headerMenuTemplate: 0 }), i1.ɵqud(335544320, 5, { headerSidenavTemplate: 0 }), i1.ɵqud(335544320, 6, { headerSocialTemplate: 0 }), i1.ɵqud(335544320, 7, { headerSubmenuTemplate: 0 }), i1.ɵqud(335544320, 8, { headerUnitSelectorTemplate: 0 }), i1.ɵqud(335544320, 9, { signupTopTemplate: 0 }), i1.ɵqud(335544320, 10, { signupBottomTemplate: 0 }), (_l()(), i1.ɵand(0, [[5, 2], ["headerSidenavTemplate", 2]], null, 0, null, View_HeaderComponent_1)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "ae2-header-layout-placement-widget", [], [[2, "ae2-header-layout-placement-widget", null]], null, null, i13.View_Ae2HeaderLayoutPlacementWidgetComponent_0, i13.RenderType_Ae2HeaderLayoutPlacementWidgetComponent)), i1.ɵdid(14, 114688, null, 0, i14.Ae2HeaderLayoutPlacementWidgetComponent, [i8.Store, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.headerConfig; var currVal_1 = _co.signupConfig; _ck(_v, 1, 0, currVal_0, currVal_1); _ck(_v, 14, 0); }, function (_ck, _v) { var currVal_2 = true; _ck(_v, 13, 0, currVal_2); }); }
export function View_HeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, View_HeaderComponent_0, RenderType_HeaderComponent)), i1.ɵdid(1, 114688, null, 0, i15.HeaderComponent, [i12.ObservableMedia], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HeaderComponentNgFactory = i1.ɵccf("app-header", i15.HeaderComponent, View_HeaderComponent_Host_0, {}, {}, []);
export { HeaderComponentNgFactory as HeaderComponentNgFactory };
