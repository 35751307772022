
<ae2-site-settings>
    <ng-template #ae2SiteSettingsContent let-site="site">
        <footer>
            <section class="footer-top">
                <div class="footer-top-logo">
                    <img class="footer-top-logo-image" src="/assets/images/logo-footer.png" alt="logo arena planet health">
                </div>
                <div class="footer-top-links" fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="center start"
                    fxLayoutAlign.xs="center center" fxLayoutGap.xs="30px">
                    <div class="mobileLink" fxFlex="15%" fxFlex.sm="33%" fxFlex.xs="auto">
                        <h2 class="footer-top-title">A Arena</h2>
                        <mat-list role="list">
                            <mat-list-item role="listitem" routerLink="/pacotes">Planos</mat-list-item>
                        </mat-list>
                    </div>
                    <div class="mobileLink" fxFlex="15%" fxFlex.sm="33%" fxFlex.xs="auto">
                        <h2 class="footer-top-title">Atividade</h2>
                        <mat-list role="list">
                            <mat-list-item role="listitem" routerLink="/nossos-treinos">Treinos</mat-list-item>
                            <mat-list-item role="listitem" routerLink="/instrutores">Coaches</mat-list-item>
                            <mat-list-item role="listitem" routerLink="/servicos-especiais">Serviços especiais</mat-list-item>
                        </mat-list>
                    </div>
                    <div class="mobileLink" fxFlex="20%" fxFlex.sm="33%" fxFlex.xs="auto">
                        <h2 class="footer-top-title">Nos encontre</h2>
                        <div class="addresCustom" [innerHTML]="site | addressCreate"></div>
                        <div class="googlePin"><a href="https://www.google.com/maps/place/Arena+Planet+Health/@-23.0086323,-43.3649007,18.83z/data=!4m5!3m4!1s0x9bdb886e51433b:0x4159531636e4457!8m2!3d-23.0086318!4d-43.3651722" target="_blank">
                            <mat-icon>room</mat-icon>Ver no Google Maps
                        </a></div>
                    </div>
                    <div class="mobileLink" fxFlex="35%" fxFlex.sm="33%" fxFlex.xs="auto">
                        <h2 class="footer-top-title">Fale conosco</h2>
                        <mat-list role="list">
                            <ng-container *ngIf="site?.phone">
                                <mat-list-item role="listitem">{{site?.phone}}</mat-list-item>
                            </ng-container>
                            <ng-container *ngIf="site?.mobile">
                                <mat-list-item role="listitem">{{site?.mobile}}</mat-list-item>
                            </ng-container>
                            <ng-container *ngIf="site?.contactEmail">
                                <mat-list-item role="listitem">{{site?.contactEmail}}</mat-list-item>
                            </ng-container>
                        </mat-list>
                        <ae2-unit-list-container>
                            <ng-template #unitListTemplate let-results="results">
                                <ng-container *ngFor="let item of results">
                                    <div>
                                        <div class="footer-top-titleHour">Horários de Funcionamento:</div>
                                        <div class="addresCustom">Segunda a sexta: {{item?.workingTimeWeek}}</div>
                                        <div class="addresCustom">Sábados e feriados: {{item?.workingTimeSaturday}}</div>
                                        <div class="addresCustom">Domingos: {{item?.workingTimeSunday}}</div>
                                    </div>
                                </ng-container>
                            </ng-template>
                        </ae2-unit-list-container>
                    </div>
                    <div class="mobileLink" fxFlex="15%" fxFlex.sm="33%" fxFlex.xs="auto">
                        <h2 class="footer-top-title">Redes Sociais</h2>
                        <div fxLayout fxLayoutAlign="start center">
                            <div matLine *ngIf="site.socialTwitter" (click)="targetLint(site.socialTwitter)">
                                <i class="iconFooter fa fa-twitter" aria-hidden="true"></i>
                            </div>
                            <div matLine *ngIf="site.socialInstagram" (click)="targetLint(site.socialInstagram)">
                                <i class="iconFooter fa fa-instagram" aria-hidden="true"></i>
                            </div>
                            <div matLine *ngIf="site.socialFacebook" (click)="targetLint(site.socialFacebook)">
                                <i class="iconFooter fa fa-facebook" aria-hidden="true"></i>
                            </div>
                            <div matLine *ngIf="site.socialSpotify" (click)="targetLint(site.socialSpotify)">
                                <i class="iconFooter fa fa-spotify" aria-hidden="true"></i>
                            </div>
                            <div matLine *ngIf="site.socialYoutube" (click)="targetLint(site.socialYoutube)">
                                <i class="iconFooter fa fa-youtube" aria-hidden="true"></i>
                            </div>
                            <div matLine *ngIf="site.socialLinkedin" (click)="targetLint(site.socialLinkedin)">
                                <i class="iconFooter fa fa-linkedin" aria-hidden="true"></i>
                            </div>
                            <div matLine *ngIf="site.socialVimeo" (click)="targetLint(site.socialVimeo)">
                                <i class="iconFooter fa fa-vimeo" aria-hidden="true"></i>
                            </div>
                            <div matLine *ngIf="site.socialFlickr" (click)="targetLint(site.socialFlickr)">
                                <i class="iconFooter fa fa-flickr" aria-hidden="true"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="footer-bottom">
                <div class="footer-bottom-content" fxLayout fxLayout.xs="column" fxLayoutAlign="space-between center">
                    <div>Copyright © 2018. Todos os direitos reservados.</div>
                    <div>
                        <a class="footer-bottom-content-link" href="https://angulare.app" target="_blank">Powered
                            by Angular e-Commerce</a>
                    </div>
                </div>
            </section>
        </footer>
    </ng-template>
</ae2-site-settings>
