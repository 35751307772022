import { NgModule } from '@angular/core';
import { PackageComponent } from './package.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { Ae2PackageWidgetModule } from '@angularecommerce/core/components/package-widget';
import { Ae2PackageListContainerModule } from '@angularecommerce/core/components/package-list-container';
import { Ae2AddToCheckoutModule } from '@angularecommerce/core/directives/add-to-checkout/add-to-checkout.module';
import { Ae2AddAnalyticsModule } from '@angularecommerce/core/directives/add-analytics';
import { Ae2ImpressionAnalyticsModule } from '@angularecommerce/core/directives/impression-analytics';
import { Ae2DialogServiceModule } from '@angularecommerce/core/services/dialog';
import { Ae2DetailAnalyticsModule } from '@angularecommerce/core/directives/detail-analytics';
import { Ae2CategoryListContainerModule } from '@angularecommerce/core/components/category-list-container/category-list-container.module';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    RouterModule,
    Ae2PackageWidgetModule,
    Ae2PackageListContainerModule,
    Ae2AddToCheckoutModule,
    Ae2AddAnalyticsModule,
    Ae2ImpressionAnalyticsModule,
    Ae2DialogServiceModule,
    Ae2DetailAnalyticsModule,
    Ae2CategoryListContainerModule
  ],
  declarations: [PackageComponent],
  exports: [PackageComponent]
})
export class PackageModule { }
