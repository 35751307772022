<section class="trainings">
    <div class="trainings-content">
        <h2 class="trainings-content-title">CONHEÇA NOSSOS TREINOS</h2>
        <div>
            <ae2-activity-list-container [active]="true" [showInActivitiesPage]="true">
                <ng-template #activityListTemplate let-results="results">
                    <!-- <ul>
                        <li *ngFor="let item of results"> {{ item.name }}</li>
                    </ul> -->
                    <div class="trainings-content-names" fxLayout="row wrap" fxLayoutGap="15px" fxLayoutAlign="center center">
                        <ng-container *ngFor="let item of results">
                            <div class="trainings-content-name">{{ item?.name }}</div>
                        </ng-container>
                    </div>
                </ng-template>
            </ae2-activity-list-container>

        </div>
        <div fxLayoutAlign="center center">
            <button class="trainings-buttom" routerLink="/nossos-treinos" mat-stroked-button color="accent">SAIBA MAIS</button>
        </div>
    </div>
</section>