import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  host: {
    '[class.footer]': 'true'
  }
})
export class FooterComponent {

  targetLint(link: string): void {
    window.open(link, '_blank');
  }
}
