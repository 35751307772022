import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-check-trainings',
  templateUrl: './check-trainings.component.html',
  styleUrls: ['./check-trainings.component.scss']
})
export class CheckTrainingsComponent implements OnInit {

  ngOnInit(): void {
  }
}
