/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./check-trainings.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "@angular/cdk/bidi";
import * as i5 from "@angular/common";
import * as i6 from "../../../../../node_modules/@angularecommerce/core/components/activity-list-container/activity-list-container.component.ngfactory";
import * as i7 from "@angularecommerce/core/classes/list-container-base/list-container-base";
import * as i8 from "@angularecommerce/core/components/activity-list-container/activity-list-container.component";
import * as i9 from "@angularecommerce/core/services/activities/activities.service";
import * as i10 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i11 from "@angular/material/button";
import * as i12 from "@angular/cdk/platform";
import * as i13 from "@angular/cdk/a11y";
import * as i14 from "@angular/platform-browser/animations";
import * as i15 from "@angular/router";
import * as i16 from "./check-trainings.component";
var styles_CheckTrainingsComponent = [i0.styles];
var RenderType_CheckTrainingsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CheckTrainingsComponent, data: {} });
export { RenderType_CheckTrainingsComponent as RenderType_CheckTrainingsComponent };
function View_CheckTrainingsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "trainings-content-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.name); _ck(_v, 2, 0, currVal_0); }); }
function View_CheckTrainingsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "trainings-content-names"], ["fxLayout", "row wrap"], ["fxLayoutAlign", "center center"], ["fxLayoutGap", "15px"]], null, null, null, null, null)), i1.ɵdid(1, 737280, null, 0, i2.LayoutDirective, [i3.MediaMonitor, i1.ElementRef, i3.StyleUtils], { layout: [0, "layout"] }, null), i1.ɵdid(2, 1785856, null, 0, i2.LayoutGapDirective, [i3.MediaMonitor, i1.ElementRef, [6, i2.LayoutDirective], i1.NgZone, i4.Directionality, i3.StyleUtils], { gap: [0, "gap"] }, null), i1.ɵdid(3, 737280, null, 0, i2.LayoutAlignDirective, [i3.MediaMonitor, i1.ElementRef, [6, i2.LayoutDirective], i3.StyleUtils], { align: [0, "align"] }, null), (_l()(), i1.ɵand(16777216, [[1, 2]], null, 1, null, View_CheckTrainingsComponent_2)), i1.ɵdid(5, 802816, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = "row wrap"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "15px"; _ck(_v, 2, 0, currVal_1); var currVal_2 = "center center"; _ck(_v, 3, 0, currVal_2); var currVal_3 = _v.context.results; _ck(_v, 5, 0, currVal_3); }, null); }
export function View_CheckTrainingsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "section", [["class", "trainings"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 14, "div", [["class", "trainings-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h2", [["class", "trainings-content-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["CONHE\u00C7A NOSSOS TREINOS"])), (_l()(), i1.ɵeld(4, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 4, "ae2-activity-list-container", [], null, null, null, i6.View_Ae2ActivityListContainerComponent_0, i6.RenderType_Ae2ActivityListContainerComponent)), i1.ɵprd(6144, null, i7.Ae2ListContainerBase, null, [i8.Ae2ActivityListContainerComponent]), i1.ɵdid(7, 4833280, null, 1, i8.Ae2ActivityListContainerComponent, [i9.Ae2ActivitiesService, i1.ChangeDetectorRef], { active: [0, "active"], showInActivitiesPage: [1, "showInActivitiesPage"] }, null), i1.ɵqud(603979776, 1, { templateRef: 0 }), (_l()(), i1.ɵand(0, [[1, 2], ["activityListTemplate", 2]], null, 0, null, View_CheckTrainingsComponent_1)), (_l()(), i1.ɵeld(10, 0, null, null, 5, "div", [["fxLayoutAlign", "center center"]], null, null, null, null, null)), i1.ɵdid(11, 737280, null, 0, i2.LayoutAlignDirective, [i3.MediaMonitor, i1.ElementRef, [8, null], i3.StyleUtils], { align: [0, "align"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 3, "button", [["class", "trainings-buttom"], ["color", "accent"], ["mat-stroked-button", ""], ["routerLink", "/nossos-treinos"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_MatButton_0, i10.RenderType_MatButton)), i1.ɵdid(13, 180224, null, 0, i11.MatButton, [i1.ElementRef, i12.Platform, i13.FocusMonitor, [2, i14.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), i1.ɵdid(14, 16384, null, 0, i15.RouterLink, [i15.Router, i15.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, 0, ["SAIBA MAIS"]))], function (_ck, _v) { var currVal_0 = true; var currVal_1 = true; _ck(_v, 7, 0, currVal_0, currVal_1); var currVal_2 = "center center"; _ck(_v, 11, 0, currVal_2); var currVal_5 = "accent"; _ck(_v, 13, 0, currVal_5); var currVal_6 = "/nossos-treinos"; _ck(_v, 14, 0, currVal_6); }, function (_ck, _v) { var currVal_3 = (i1.ɵnov(_v, 13).disabled || null); var currVal_4 = (i1.ɵnov(_v, 13)._animationMode === "NoopAnimations"); _ck(_v, 12, 0, currVal_3, currVal_4); }); }
export function View_CheckTrainingsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-check-trainings", [], null, null, null, View_CheckTrainingsComponent_0, RenderType_CheckTrainingsComponent)), i1.ɵdid(1, 114688, null, 0, i16.CheckTrainingsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CheckTrainingsComponentNgFactory = i1.ɵccf("app-check-trainings", i16.CheckTrainingsComponent, View_CheckTrainingsComponent_Host_0, {}, {}, []);
export { CheckTrainingsComponentNgFactory as CheckTrainingsComponentNgFactory };
