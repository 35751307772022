import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './footer.component';
import { MatListModule } from '@angular/material';
import { Ae2SiteSettingsModule } from '@angularecommerce/core/components/site-settings/site-settings.module';
import { AddressPipeModule } from '../../pipes/address/address.module';
import { ContactModule } from '../contact/contact.module';
import { MatIconModule } from '@angular/material';
import { Ae2UnitListContainerModule } from '@angularecommerce/core/components/unit-list-container';

@NgModule({
  imports: [
    FlexLayoutModule,
    CommonModule,
    RouterModule,
    MatListModule,
    Ae2SiteSettingsModule,
    AddressPipeModule,
    ContactModule,
    MatIconModule,
    Ae2UnitListContainerModule
  ],
  declarations: [FooterComponent],
  exports: [FooterComponent]
})
export class FooterModule { }
