import { NgModule } from '@angular/core';
import { Ae2HeaderWidgetModule } from '@angularecommerce/core/components/header-widget';
import { HeaderComponent } from './header.component';
import { MatButtonModule, MatIconModule, MatListModule } from '@angular/material';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    RouterModule,
    Ae2HeaderWidgetModule,
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatListModule
  ],
  declarations: [HeaderComponent],
  exports: [HeaderComponent],
})
export class HeaderModule { }
