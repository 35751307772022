import { Component, OnInit } from '@angular/core';
import {
    Ae2HeaderWidgetConfig, Ae2HeaderLinkToSession, Ae2HeaderLinkToPage,
    Ae2HeaderSubmenu, Ae2HeaderMenu
} from '@angularecommerce/core/components/header-widget';
import { ObservableMedia } from '@angular/flex-layout';
import { Ae2SignUpStepperConfig, Ae2SignUpField } from '@angularecommerce/core/components/sign-up-steps';
import { Ae2SignUpFieldValidator, Ae2SignupFieldTypes } from '@angularecommerce/core/components/sign-up-steps';
import {
    Ae2SignUpStepSignup,
    Ae2SignUpStepAddress,
    Ae2SignUpStepFitness,
    Ae2SignUpStepProfile
} from '@angularecommerce/core/components/sign-up-steps/sign-up-stepper/sign-up-default-steps.model';


export class CustomSignUpStepFitness extends Ae2SignUpStepFitness {

    constructor() {
        super();

        this.fields = [
            new Ae2SignUpField({
                name: 'shoeSize',
                internalname: 'shoe_size',
                label: 'core.signUpSteps.stepper.labels.showSize',
                validators: new Ae2SignUpFieldValidator({ required: false }),
                row: 1,
                size: '48.5%'
            }),
            new Ae2SignUpField({
                name: 'tshirtSize',
                internalname: 'tshirt_size',
                label: 'core.signUpSteps.stepper.labels.tshirtSize',
                validators: new Ae2SignUpFieldValidator({
                    type: Ae2SignupFieldTypes.SELECT, required: false, options: [
                        { value: 'Baby Look', label: 'core.global.tshirtSizes.babyLook' },
                        { value: 'P', label: 'core.global.tshirtSizes.p' },
                        { value: 'M', label: 'core.global.tshirtSizes.m' },
                        { value: 'G', label: 'core.global.tshirtSizes.g' },
                        { value: 'GG', label: 'core.global.tshirtSizes.gg' }
                    ]
                }),
                row: 1,
                size: '48.5%'
            })
        ];
    }
}

export const CustomAe2SignupConfig = new Ae2SignUpStepperConfig([
    new Ae2SignUpStepSignup(),
    new Ae2SignUpStepProfile(),
    new Ae2SignUpStepAddress()
]);

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    headerConfig: Ae2HeaderWidgetConfig = new Ae2HeaderWidgetConfig();
    signupConfig: Ae2SignUpStepperConfig;
    constructor(
        private mq: ObservableMedia
    ) { }

    ngOnInit(): void {
        this.createSignupConfig();
        this.headerConfig = new Ae2HeaderWidgetConfig();
        this.headerConfig.menuItems = new Ae2HeaderMenu();
        this.headerConfig.menuItems.items = [
            new Ae2HeaderSubmenu('A Arena', [
                new Ae2HeaderLinkToPage('Sistema de Resultados', '/sistema-de-resultados'),
                new Ae2HeaderLinkToPage('Treinos', '/nossos-treinos'),
                new Ae2HeaderLinkToPage('Serviços Especiais', '/servicos-especiais'),
            ]),
            new Ae2HeaderLinkToPage('Equipe', '/instrutores'),
            new Ae2HeaderLinkToPage('Planos', '/pacotes'),
            new Ae2HeaderLinkToPage('Dúvidas Frequentes', '/faq')
        ];
        this.headerConfig.backgroundColor = '#005286';
        this.headerConfig.transparentOnRoutes = [];
    }

    createSignupConfig(): void {
        this.signupConfig = CustomAe2SignupConfig;
    }

}
